.rgbtextdivider, .rgbtextdivider>* {
   position: absolute;
   top: 50%;
   left: 50%;
   transform: translate(-50%, -50%);
}

.rgbtextdivider>* {
   font-size: 80px;
   animation: giggle 1s ease infinite;
   mix-blend-mode: difference;
}

.rgbtextdivider>.r {
   color: #f00;
   left: -0.5px;
}

.rgbtextdivider>.g {
   color: #0f0;
   animation-delay: -0.67s;
}

.rgbtextdivider>.b {
   color: #00f;
   animation-delay: -0.33s;
   left: 0.5px;
}

@keyframes giggle {
   0%, 100% {
      transform: translate(-50%, -50%) translateY(-1px);
   }
   50% {
      transform: translate(-50%, -50%) translateY(1px);
   }
}
body {
  margin: 0;
  padding: 0;
  font-family: 'Source Sans Pro', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color:#323133;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body:after {
  content: '';
  position: absolute;
  top:0px;
  left:0px;
  right:0px;
  bottom:0px;
  z-index:1000;
  background: repeating-linear-gradient(0deg, #111 0px, #111 1px, transparent 2px, transparent 5px);
  background-size:100% 5px;
  animation: lines 2s ease-out infinite;
  opacity:0.3;
  mix-blend-mode: color-burn;
  pointer-events: none;
}
@keyframes lines {
   0% {
      background-position:0px 0px;
   }
   100% {
      background-position:0px 25px;
   }
}